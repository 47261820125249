import React, { useState, useEffect } from 'react';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { AuthProvider, useAuth } from "../../providers/auth";

const api = process.env.REACT_APP_HOST;

const CollapseTurmas = ({ title, children, codcoligada, codfilial, codturma, codperlet, ra, isOpenByDefault, id, getMaterialDidatico }) => {
    const { user, setUser } = useAuth();
    const [isOpen, setIsOpen] = useState(isOpenByDefault ? isOpenByDefault : false);

    async function getEnotas(coligada, filial) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            codcoligada: coligada,
            codfilial: filial,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        let idempresa = await fetch(`${api}/dadosenotasfilial`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                if (data.record_count > 0) {
                    return data.dados[0].IDEMPRESAENOTAS;
                } else {
                    return 'NAO_INTEGRADA'
                }
            })
            .catch(error => {
                // console.error('Erro na requisição:', error);
                return error
            });

        return idempresa;
    }

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            localStorage.setItem(
                "@meuboleto-app/filial",
                codfilial
            );
            getEnotas(codcoligada, codfilial)
                .then(id => {
                    setUser({
                        ...user,
                        ra: ra,
                        codcoligada: codcoligada,
                        codfilial: codfilial,
                        codturma: codturma,
                        codperlet: codperlet,
                        idempresaenotas: id
                    });
                })
                .catch((error) => {
                    setUser({
                        ...user,
                        ra: ra,
                        codcoligada: codcoligada,
                        codfilial: codfilial,
                        codturma: codturma,
                        codperlet: codperlet,
                        idempresaenotas: "NAO_INTEGRADA"
                    });
                })
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id === 0) {
                try {
                    const idempresa = await getMaterialDidatico(codcoligada, codturma, codperlet);

                    setUser({
                        ...user,
                        ra: ra,
                        codcoligada: codcoligada,
                        codfilial: codfilial,
                        codturma: codturma,
                        codperlet: codperlet,
                        idempresaenotas: idempresa
                    });
                } catch (error) {
                    setUser({
                        ...user,
                        ra: ra,
                        codcoligada: codcoligada,
                        codfilial: codfilial,
                        codturma: codturma,
                        codperlet: codperlet,
                        idempresaenotas: "NAO_INTEGRADA"
                    });
                }
            }
        };

        fetchData();
    }, [id, codcoligada, codfilial, codturma, codperlet, ra, setUser, getMaterialDidatico]);


    return (
        // <Card style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px', marginTop: '20px' }}>
        <>
            <Card>
                <CardHeader onClick={toggleCollapse} style={{ height: '40px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f7f7f7' }}>
                    <span style={{ color: '#000000', fontSize: '14px', fontWeight: '500' }}>{title}</span>
                    {isOpen ? <FaAngleUp style={{ color: '#000' }} /> : <FaAngleDown style={{ color: '#000' }} />}
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody style={{ padding: '0', marginTop: '15px', marginBottom: '15px' }}>{children}</CardBody>
                </Collapse>
            </Card>
        </>
    );
};

export default CollapseTurmas;
